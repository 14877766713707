import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import ContactForm from '../ContactForm';
import Section from '../Section';
import ContactInfoSection from '../Section/ContactInfoSection';
import { pageTitle } from '../../helpers/PageTitle';
import emailjs from 'emailjs-com'





export default function Contact() {
  pageTitle('Contact');
  return (
    <>
    <section
      className="cs_hero cs_style_2 cs_bg_filed"
      style={{ 
        backgroundImage: `url(/images/home_1/contactbanner.png)` , 
        height: '20vh',
        minHeight: '480px',
        maxHeight: '20vh',
        padding: '180px 0 0',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="container">
        <div className="cs_hero_text">
          <h1 className="cs_fs_84" style={{ color: '#fff', marginBottom: '500px !important'}}>{'Contact Us'}</h1>
          <div className="cs_hero_text_in">
            <p className="cs_hero_subtitle cs_white_color"> Let us discuss your unique requirements and discover how we can help your healthcare practice flourish.  </p>
          </div>
        </div>
        {/* <img src={imgUrl} alt="Hero" className="cs_hero_patents" /> */}
      </div>
      <div className="container">
        <div className="cs_hero_bottom">
          <div className="cs_hero_bottom_left">
         
          </div>
        </div>
      </div>
    </section>
     
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={100}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}

