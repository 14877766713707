import React from "react";
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
import Section from "../Section";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import DoctorDetailsSection from "../Section/DoctorDetailsSection";
import AppointmentSectionStyle2 from "../Section/AppointmentSection/AppointmentSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import {
  Awards,
  ContactInfo,
  Degrees,
  Experiences,
  Schedules,
  SocialData,
} from "./constants/DoctorDetailsConstants";

export default function DoctorDetails() {
  pageTitle("Doctor Details");
  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <DoctorDetailsSection
          bgUrl="/images/doctors/doctor_details_bg.svg"
          imgUrl="/images/doctors/doctor_details.jpeg"
          name="Dr. Sarah Lee, MD, MPH, FAPA"
          department="Psychiatry Department"
          designation="Board-certified Psychiatrist"
          description="With more than 15 years of experience learning human psychology and behavior, Dr. Jones is an expert in managing mood disorders and anxiety disorders"
          social={SocialData}
          contactInfo={ContactInfo}
          contactInfoHeading="Contact Info"
          schedules={Schedules}
          scheduleHeading="Appointment Schedules"
          degrees={Degrees}
          degreesHeading="Degrees"
          experiences={Experiences}
          experiencesHeading="Experiences"
          awards={Awards}
          awardHeading="Awards/Achievements"
        />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle="BOOK AN"
          sectionTitleUp=""
        />
      </Section>

      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
