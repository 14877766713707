import React from "react";
import parser from "html-react-parser";

export default function BannerSectionStyle4({
  bgUrl,
  title,
  subTitle,
  contUrl,
  center,
}) {
  return (
    <div className="container" style={{borderRadius: '5rem', Position: 'relative'}}>
      <div
        className={`cs_banner cs_style_4 cs_bg_filed overflow-hidden  ${
          center ? "text-center" : ""
        }`}
        style={{ position: "relative", borderRadius: "2rem", height: "10rem",}} // Ensure the parent div is positioned relatively
      >
        {/* Background layer with reduced opacity */}
        <div
          className="my_bg_image"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${bgUrl})`,
            opacity: 1,
            zIndex: -1,
            backgroundSize: "cover", // Ensure the image covers the full div
            backgroundPosition: "center",
            objectFit: "cover",
            objectPosition: "center",
            filter: "blur(2px)"
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "#000000",
              opacity: '0.7'
            }}
          ></div>
        </div>

        {/* Content */}
        <h2 className="cs_banner_title cs_fs_72" style={{color: "#fff"}}>
          {parser(title)}
        </h2>
        <h3 className="cs_banner_subtitle cs_fs_20 m-0" style={{color: "#fff"}}>
          {parser(subTitle)}
        </h3>
        <div className="center-button mt-5">
          <a
            href={contUrl}
            className="cs_button cs_white_color cs_button_primary about-btn-primary"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}
