import React from "react";
import Banner from '../Section/BannerSection';
import Section from "../Section";
import DepartmentSectionStyle2 from "../Section/DepartmentSection/DepartmentSectionStyle2";
import FeaturesSectionStyle2 from "../Section/FeaturesSection/FeaturesSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
const departmentData = [
  {
    title: "Integrity",
    subTitle:
      "We understand the importance of trust in the care industry and commit to providing honest guidance and solutions, allowing clients to focus on delivering quality care. ",
    iconUrl: "/images/icons/professional.svg",
  },
  {
    title: "Commitment To Excellence",
    subTitle:
      " We focus on delivering exceptional healthcare services by optimising processes and improving patient experiences.",
    iconUrl: "/images/icons/patient.svg",
  },

  {
    title: "Client Centered Care",
    subTitle:
      "We assist with tailored solutions based on individual challenges and goals to empower our clients thus delivering exceptional care services. ",
    iconUrl: "/images/icons/facilities.svg",
  },
];

const featureListData = [
  {
    title: "Navigating Regulations",
    subTitle:
"We guide you through CQC and Ofsted compliance, ensuring you meet the latest requirements.",
    iconUrl: "images/icons/professional.svg",
  },
  {
    title: "CQC and Ofsted Applications",
    subTitle:
        "We assist with application preparation, documentation, and liaison with regulatory bodies. ",    iconUrl: "images/icons/comprehensive.svg",
  },
  {
    title: "Tender Writing",
    subTitle:
      "Our team crafts compelling tenders to help you stand out in competitive processes.",    iconUrl: "images/icons/patient.svg",
  },
];



export default function About() {
  pageTitle("About");
  return (
    <>
    <section
      className="cs_hero cs_style_2 cs_bg_filed"
      style={{ 
        backgroundImage: `url(/images/home_1/aboutbanner.png)` , 
        height: '20vh',
        minHeight: '480px',
        maxHeight: '20vh',
        padding: '180px 0 0',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="container">
        <div className="cs_hero_text">
          <h1 className="cs_fs_84" style={{ color: '#fff', marginBottom: '500px !important'}}>{'About Us'}</h1>
          <div className="cs_hero_text_in">
            <p className="cs_hero_subtitle cs_white_color">With 30 years of experience,Cosesha empowers medical practices to thrive. 
        Our expert consultants guide you in practice management, compliance, and growth.  Your success is our focus.
            </p>
          </div>
        </div>
        {/* <img src={imgUrl} alt="Hero" className="cs_hero_patents" /> */}
      </div>
      <div className="container">
        <div className="cs_hero_bottom">
          <div className="cs_hero_bottom_left">
            {/* <div className="cs_hero_btn_wrap">
              <VideoModal
                videoUrl={videoUrl}
                videoBtnText={videoBtnText}
                variant="cs_white_color"
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
      {/* <BannerSectionStyle3
        bgUrl="/images/banner.png"
        imgUrl="/images/about/about_main.jpg"
        title="Welcome to <br />Cosesha Medical Consultancy"
        subTitle="  "
      /> */}
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Who We Are!"
          imgUrl="images/about/progress.jpg"
          data={featureListData}
        />
      </Section>
      <Section>
        <DepartmentSectionStyle2
          sectionTitle="VALUES "
          sectionTitleUp=" Our Core Principles"
          data={departmentData}
        />
      </Section>
      {/* <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section> */}
      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      ></Section> */}


      <Section>
        <Banner
          bgUrl="images/home_1/contact_us_now.jpg"
          // imgUrl="images/home_1/start_consulting.jpg"
          title=" Get Started"
          subTitle="Contact us today for a consultation. We are here to help you!"
          contUrl={'/contact'}
        />
      </Section>
    </>
  );
}
