import React, { useEffect, useState } from "react";
import BannerSectionStyle7 from "../Section/BannerSection/BannerSectionStyle7";
import Section from "../Section";
import AppointmentSection from "../Section/AppointmentSection";
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
import AboutSectionStyle2 from "../Section/AboutSection/AboutSectionStyle2";
import FeaturesSectionStyle3 from "../Section/FeaturesSection/FeaturesSectionStyle3";
import TeamSectionStyle3 from "../Section/TeamSection/TeamSectionStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";
import AboutSectionStyle3 from '../Section/AboutSection/AboutSectionStyle3';


const featureData = [
  {
    title: "Vaccinations Department",
    subTitle:
      "The Pediatric Department provides vaccinations to help protect children from a range of illnesses and diseases, including measles.",
    iconUrl: "/images/departments/icon_9.svg",
  },
  {
    title: "",
    subTitle:
      "The Pediatric Department provides treatment for common childhood illnesses, such as ear infections, strep throat, and viral infections.",
    iconUrl: "/images/departments/icon_10.svg",
  },
  {
    title: "Treatment of chronic conditions",
    subTitle:
      "The Pediatric Department provides ongoing care and treatment for children with chronic conditions such as asthma, diabetes, and allergies.",
    iconUrl: "/images/cosesha-logo-final.png",
  },
  {
    title: "Developmental screenings",
    subTitle:
      "The Pediatric Department provides regular developmental screenings to identify any delays or concerns and provide early intervention services.",
    iconUrl: "/images/departments/icon_12.svg",
  },
];

const doctorData = [
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    availableUrl: "/",
    callUrl: "/",
    chatUrl: "/",
    btnText: "Booking",
    btnUrl: "/appointments",
  },
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
  
  },
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
  
  },
  
];

const servicesData = [
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Setting Up a Care Company",
    subTitle:
      "aesdfghj a care company can be daunting, At Cosesha Consultancy, we provide comprehensive guidance from the intitial concept to full operational setup. Our services include business planning, legal compliance, and ongoing support to ensure your success",
    href: "services-details",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "CQC Registration",
    subTitle:
      "Navigating the CQC registration process is critical for your care service's compliance and credibility. We offer step-by step assistance, ensuring all documentation is in order and preparing you for inspections",
    href: "ServicesDetails1",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Recieving Refferals",
    subTitle:
      "We help you increase your client base through effective refferal strategies, networking, and partnerships with local authorities and healthcare providers.",
    href: "ServicesDetails2",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Managing Embagoes",
    subTitle:
      "If your service is an embargo, our expert team will work with you to adress the issues and lift the restrictions effeciently",
    href: "/services-details3",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Staff training and Management",
    subTitle:
      "Customised training programme for staff dealing with complex cases.\nOngoing support for staff development and quality assurance.",
    href: "services-details4",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Sponsorship Licence Management",
    subTitle:
      "Application and management of sponsorship licence.\nAssistance with revoked sponsorship licences and compliance issues.",
    href: "services-details5",
  },
];

export default function ServicesDetails() {
  let id = useParams();
  const [isID, setIsID] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    servicesData.map((item) => {
      if (id.serviceId === item.href) {
        setIsID(true);
        setData({
          title: item.title,
          subTitle: item.subTitle,
          imgUrl: item.imgUrl,
        });
      }
      console.log(id);
      console.log(isID);
      console.log(data);
    });
  }, []);

  pageTitle("Services Details");
  return (
    <>
      <BreadcrumbStyle2 />
      <Section topMd={135} topLg={100} topXl={100}>
        {isID && (
          <AboutSectionStyle2
            title={data.title}
            subTitle={data.subTitle}
            imgUrl="/images/setting_up_a_care_company_details.jpg"
          />
        )}
      </Section>
      <Section topMd={200} topLg={150} topXl={100}>
        <TeamSectionStyle3 sectionTitle="Related Doctor" data={doctorData} />
      </Section>

      {/* Start Appointment Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={190}
        bottomLg={145}
        bottomXl={110}
        id="appointment"
      >
        <AppointmentSection
          sectionTitle="BOOK AN"
          sectionTitleUp="Appointment"
          imgUrl="/images/make-an_appointment.jpg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle7
          imgUrl="/images/departments/banner_img_3.png"
          bgUrl="/images/departments/banner_bg_3.svg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section> */}
    </>
  );
}
