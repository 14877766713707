import React from 'react';
import Slider from 'react-slick';
import TeamStyle3 from '../Team/TeamStyle3';

export default function TeamSlider({ data }) {
  /** Slider Settings **/
 
  const settings = {
   
  };
  return (
    <Slider
      {...settings}
      className="cs_gap_20 cs_cs_slider_navigation_2 cs_related_doctor position-relative"
    >
      {data?.map((item, index) => (
        <div key={index}>
          <TeamStyle3 {...item} />
        </div>
      ))}
    </Slider>
  );
}
