import React from "react";
import Section from "../Section";
import DepartmentSectionStyle3 from "../Section/DepartmentSection/DepartmentSectionStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import Banner from '../Section/BannerSection';
const servicesData = [
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Setting Up a Care Company",
    subTitle:
      "Cosecha simplifies running a care practice. We offer expert guidance on operations, regulations, and finances, keeping you informed about changes that impact your practice.",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Custom Website Design and Development",
    subTitle:
      "Get a website that reflects your brand and captures leads. We design user-friendly experiences with all the info your audience needs.",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Integration of Management Tools",
    subTitle:
      "Effortless care management. Integrate essential software for scheduling, appointments & communication.",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Staffing Management Portal",
    subTitle:
      "A centralised hub for all your staff-related needs. Manage employee profiles, schedules, leave requests, and training records, all in one convenient location.",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Timesheet Management System",
    subTitle:
      "Ditch paper, embrace automation: Our system streamlines timesheets with electronic submissions, saving you time and boosting accuracy. ",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Transition to Paperless Operations",
    subTitle:
      "<strong>Go paperless, go efficient:</strong> Securely manage patient records, contracts, and more with our digital document system. Save space and boost efficiency.",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Referral Management",
    subTitle:
          "<strong>Referral Strategy: </strong>We develop tailored referral programmes to attract new patients. <br/> <strong>Costing Assistance:</strong> We help you budget for your referral programme, maximising ROI. ",
  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Comprehensive Staff Training",
    subTitle:
"Cosesha values your staff as your most important asset. We offer customised training programmes and ongoing development sessions to ensure your team delivers exceptional care."  },
  {
    iconUrl: "images/departments/icon_1.svg",
    title: "Sponsor Licence program",
    subTitle:
      "We simplify the Sponsor Licence application process, ensuring compliance and providing ongoing support for record-keeping and reporting. "  },
];

export default function Departments() {
  pageTitle("Services");
  return (
    <>
        <section
      className="cs_hero cs_style_2 cs_bg_filed"
      style={{ 
        backgroundImage: `url(/images/home_1/servicesbanner.png)` , 
        height: '20vh',
        minHeight: '480px',
        maxHeight: '20vh',
        padding: '180px 0 0',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="container">
        <div className="cs_hero_text">
          <h1 className="cs_fs_84" style={{ color: '#fff', marginBottom: '500px !important'}}>{'Our Services'}</h1>
          <div className="cs_hero_text_in">
            <p className="cs_hero_subtitle cs_white_color">Your one-stop shop for comprehensive business solutions.
            </p>
          </div>
        </div>
        {/* <img src={imgUrl} alt="Hero" className="cs_hero_patents" /> */}
      </div>
      <div className="container">
        <div className="cs_hero_bottom">
          <div className="cs_hero_bottom_left"></div>
        </div>
      </div>
    </section>
      {/* <BannerSectionStyle5
        bgUrl="/images/doctors/medical consultancy banner.jpeg"
        imgUrl="/images/doctors/medical-consultancy-banner.jpeg"
        title="These are  <br />Our Services"
        subTitle="Your one-stop shop for comprehensive business solutions."
      /> */}
      <Section bottomMd={10} bottomLg={100} bottomXl={5} topMd={10}>
        <DepartmentSectionStyle3 data={servicesData} />
      </Section>
      <Section>
        <Banner
          bgUrl="images/home_1/contact_us_now.jpg"
          // imgUrl="images/home_1/start_consulting.jpg"
          title=" Get Started"
          subTitle="Contact us today for a consultation. We are here to help you!"
          contUrl={'/contact'}
        />
      </Section>
    </>
  );
}
