import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';

import Home from './components/Pages/Home';

import About from './components/Pages/About';
import Doctors from './components/Pages/Doctors';
import Blog from './components/Pages/Blog';
import Appointments from './components/Pages/Appointments';
import ServiceDetails from './components/Pages/services-details';
import ServiceDetails1 from './components/Pages/service-details-1';
import ServiceDetails2 from './components/Pages/service-details-2';
import ServiceDetails3 from './components/Pages/service-details-3';
import ServiceDetails4 from './components/Pages/service-details-4';
import ServiceDetails5 from './components/Pages/service-details-5';
import DoctorDetails from './components/Pages/DoctorDetails';
import PricingPlan from './components/Pages/PricingPlan';
import Gallery from './components/Pages/Gallery';
import Timetable from './components/Pages/Timetable';
import Contact from './components/Pages/Contact';
import { useEffect } from 'react';
import ErrorPage from './components/Pages/ErrorPage';
import Services from './components/Pages/Services';
import GuidesAndChecklists from './components/Pages/guidesAndChecklists';
import FAQs from './components/Pages/FAQs';
// import ServicesDetails4 from './components/Pages/service-details-4';
// import ServicesDetails5 from './components/Pages/service-details-5';


//make another change for testing


function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="doctors" element={<Doctors />} />
        <Route path="doctors/:doctorId" element={<DoctorDetails />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:blogId" element={<GuidesAndChecklists />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="services/:serviceId"element={<ServiceDetails />} />
        <Route path="services/:service-details-1IId"element={<ServiceDetails1 />} />
        <Route path="services/:service-details-2Id"element={<ServiceDetails2 />} />
        <Route path="services/:service-details-3Id"element={<ServiceDetails3 />} />
        <Route path="services/:service-details-4Id"element={<ServiceDetails4 />} />
        <Route path="services/:serviceId-details-5Id"element={<ServiceDetails5 />} />
        <Route path="pricing-plan" element={<PricingPlan />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="faqs" element={<FAQs />} />
        <Route path="services" element={<Services />} />
        <Route path="timetable" element={<Timetable />} />
        <Route path="contact" element={<Contact />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
