export const SocialData = [
  { icon: "fa6-brands:facebook-f", href: "/about" },
  { icon: "fa6-brands:linkedin-in", href: "/about" },
  { icon: "fa6-brands:twitter", href: "/about" },
];

export const ContactInfo = [
  { iconUrl: "/images/icons/call.svg", title: "+123+456-7890" },
  {
    iconUrl: "/images/icons/envlope.svg",
    title: "sarahlee@prohealth.com",
  },
];

export const Schedules = [
  { day: "Monday", time: "09.00-12.00" },
  { day: "Wednesday", time: "15.00-18.00" },
  { day: "Friday", time: "09.00-12.00" },
];

export const Degrees = [
  {
    title: "University of California, San Francisco.",
    subTitle: "Medical degree",
  },
  {
    title: "University of California, Los Angeles (UCLA) Medical Center.",
    subTitle: "Completed residency training in psychiatry",
  },
  {
    title: "University of California, Berkeley.",
    subTitle: "Master of Public Health degree",
  },
];

export const Experiences = [
  {
    title:
      "Worked in community mental health clinics, private practice, and academic medical centers.",
  },
  {
    title:
      "Expertise in the treatment of mood disorders, anxiety disorders, and psychotic disorders.",
  },
  {
    title: `Special interest in women's mental health and perinatal psychiatry.`,
  },
  {
    title:
      "Experience managing complex cases that involve both mental health and medical issues.",
  },
];

export const Awards = [
  { title: "Fellow of the American Psychiatric Association (FAPA)." },
  {
    title:
      "Recognised for research contributions with grants from the National Institute of Mental Health (NIMH) and the American Foundation for Suicide Prevention.",
  },
];
