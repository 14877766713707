import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import emailjs from 'emailjs-com'



const SERVICE_ID = "service_7wthlp5";
const TEMPLATE_ID = "template_ohhhfsk";
const PUBLIC_KEY = "F-IN503S120l-gLsQ";

const AppointmentForm=() => {
const handleOnSubmit = (e) => {
  e.preventDefault();
  emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
    .then((result) => {
      alert('Message Sent Successfully')
    }, (error) => {
      console.log(error.text);
      alert('Something went wrong!')
    });
  e.target.reset()
};


;
  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg ">
    <div className="row">
    <div className="col-lg-=6">
                <form class="formContainer" onSubmit={handleOnSubmit}>
                    <h2>Send me a message. Let's have a chat!</h2>
                    <div class="formElement">
                        <label  className="cs_input_label cs_heading_color">Name</label>
                        <input   className="cs_form_field" type="text" id="from_name" name="from_name" placeholder="Your name.." required />
                        <div className="cs_height_10 cs_height_xl_1" />
                    </div>
    
                    <div className="col-lg-12">
                      
                    <label className="cs_input_label cs_heading_color">Email</label>
                        <input   className="cs_form_field" type="email" id="from_email" name="from_email" placeholder="Your email.." required />
                        <div className="cs_height_ cs_height_xl_25" />
                    </div>
    
                    <div class="formElement">
                    <textarea
        cols={100}
        rows={10}
        className="cs_form_field"
        placeholder="Write something..."
        defaultValue={''}
      />
      <div className="cs_height_42 cs_height_xl_25" />
    </div>
    <div className="col-lg-24">
      <div className="cs_height_18" /></div>
       
              <button className="cs_btn cs_style_1">
                <span>Submit</span>
                <i>
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                </i>
              </button>
              </form>
            </div>
            </div>
          </div>
          
      );
    }
    
    
    export default AppointmentForm;