// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cs_banner.cs_style_1.cs_bg_filed {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the darkening effect here */
  z-index: 1; /* Ensure it's above the background image but below the content */
}

.cs_banner_title, .cs_banner_subtitle, .center-button {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}`, "",{"version":3,"sources":["webpack://./src/sass/banner.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA,EAAA,qCAAA;EACA,UAAA,EAAA,iEAAA;AACJ;;AAEE;EACE,kBAAA;EACA,UAAA,EAAA,wCAAA;AACJ","sourcesContent":[".cs_banner.cs_style_1.cs_bg_filed {\n    position: relative;\n    overflow: hidden;\n  }\n  \n  .overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5); /* Adjust the darkening effect here */\n    z-index: 1; /* Ensure it's above the background image but below the content */\n  }\n  \n  .cs_banner_title, .cs_banner_subtitle, .center-button {\n    position: relative;\n    z-index: 2; /* Ensure content is above the overlay */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
