import React from "react";
import Section from "../Section";
// Import the GuidesAndChecklistsSection component
import { pageTitle } from "../../helpers/PageTitle";
import Banner from '../Section/BannerSection';
import FaqSection from "../Section/FaqSection";
//add a comment, commit and push to git


const faqData = [
 
  {
    title: "How do I schedule an appointment with Cosesha?",
    content:
      "Click on the contact us button on the home page to schedule an appointment with us. Our team will get back to you as soon as possible with a confirmation of your appointment. We will also send you a confirmation email with all the details of your appointment.",
  },
  {
    title: "Do you accept insurance?",
    content:
      " we can provide you with an invoice you may be able to submit to your insurance provider for potential reimbursement.  We recommend contacting your insurance company directly to inquire about their coverage for healthcare consultancy services.",
  },
  {
    title: "What should I bring to my appointment?",
    content:
      "We recommend bringing your own medical records, insurance proof, and any other relevant documentation to your appointment. We can provide you with a copy of your medical records upon request.",
  },
  {
    title: "How do I request custom service?",
    content:
      "Cosecha understands that every healthcare organization has unique needs. We are happy to discuss custom service packages tailored to your specific requirements. Please contact us to discuss your specific needs and how we can create a customised solution for you.",
  },
  {
    title: "Do you have any certifications or accreditations?",
    content:
      "We have a team of certified healthcare consultants who have completed rigorous training and certification in various areas of healthcare. We can provide you with a list of our certifications upon request.",
  },
  {
    title: "Are there any additional costs for follow-up consultations?",
    content:
      "The cost of follow-up consultations will depend on the specific service provided.  We will discuss any associated fees during your initial consultation and provide you with a detailed breakdown of costs before proceeding.",
  },
  {
    title: "How far in advance do I need to book an appointment?",
    content:
      "We recommend booking your appointment at least three days in advance to ensure availability. However, we will do our best to accommodate last-minute requests based on our schedule.",
  },
  {
    title: "What are your fees for consultation and services?",
    content:
      "Cosecha offers competitive rates for our services. The specific fee will depend on the type of service you require, the duration of the consultation, and the complexity of your needs.  We will provide you with a transparent quote before you schedule your appointment.",
  },
  {
    title: "Do you offer emergency consultations?",
    content:
      "Cosecha does not typically offer emergency consultations.  However, if you have an urgent need, we recommend contacting your primary care physician or visiting the nearest emergency room.  We can be a valuable resource for follow-up care and long-term planning after an emergency situation.",
  },
  {
    title: "How can I contact you if I have questions after my consultation?",
    content:
      "We encourage you to contact us if you have any questions after your consultation. You can reach us by phone at [Phone Number], email at [Email Address], or through our website contact form at [Website Address].",
  },
];

// const blogData = [
//   {
//     title: "Setting up care services",
//     thumbUrl: "images/setting_up_a_care_company_details.jpg",
//     date: "March 9",
//     btnText: "Learn More",
//     href: "/services",
//     socialShare: true,
//   },
//   {
//     title: `Managing care services`,

//     date: "March 11",
//     thumbUrl: "managing-services.jpg",
//     btnText: "Learn More",
//     href: "/services",
//     socialShare: true,
//   },
//   {
//     title: "Training and development programs",
//     thumbUrl: "training_services.jpg",
//     date: "March 12",
//     btnText: "Learn More",
//     href: "/services",
//     socialShare: true,
//   },


// ];

export default function FAQs() {
  pageTitle("FAQs");
  return (
    
    <>

<section
      className="cs_hero cs_style_2 cs_bg_filed"
      style={{ 
        backgroundImage: `url(/images/home_1/faqbanner.png)` , 
        height: '20vh',
        minHeight: '480px',
        maxHeight: '20vh',
        padding: '180px 0 0',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="container">
        <div className="cs_hero_text">
          <h1 className="cs_fs_84" style={{ color: '#fff', marginBottom: '500px !important'}}>{'FAQs'}</h1>
          <div className="cs_hero_text_in">
            <p className="cs_hero_subtitle cs_white_color">We provide strategic planning, regulatory compliance, clinical development, financial management, IT solutions, staff training, marketing strategies, research support, legal advisory, and policy advocacy to optimise healthcare operations and outcomes.
            </p>
          </div>
        </div>
        {/* <img src={imgUrl} alt="Hero" className="cs_hero_patents" /> */}
      </div>
      <div className="container">
        <div className="cs_hero_bottom">
          <div className="cs_hero_bottom_left">
            {/* <div className="cs_hero_btn_wrap">
              <VideoModal
                videoUrl={videoUrl}
                videoBtnText={videoBtnText}
                variant="cs_white_color"
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>

    {/* <BannerSectionStyle5
        bgUrl="/images/doctors/medical consultancy banner.jpeg"
        imgUrl="resources_banner.jpg"
        title="FAQs"
        subTitle="We provide strategic planning, regulatory compliance, clinical development, financial management, IT solutions, staff training, marketing strategies, research support, legal advisory, and policy advocacy to optimise healthcare operations and outcomes."
      /> */}
    
     
      {/* <Section bottomMd={200} bottomLg={150} bottomXl={110}></Section> */}
      <Section topMd={50} topLg={1} topXl={1}>
        <FaqSection
          data={faqData}
          sectionTitle="What people"
          sectionTitleUp="Usually Ask"
        />
      </Section>
      <Section>
        <Banner
          bgUrl="images/home_1/contact_us_now.jpg"
          // imgUrl="images/home_1/start_consulting.jpg"
          title=" Get Started"
          subTitle="Contact us today for a consultation. We are here to help you!"
          contUrl={'/contact'}
        />
      </Section>

    
    </>
  );
}
