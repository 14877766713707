import React from 'react';
import '../../../sass/banner.scss';

export default function Banner({ bgUrl, imgUrl, title, subTitle, contUrl }) {
  return (
    <div className="container">
      <div
        className="cs_banner cs_style_1 cs_bg_filed"
        style={{ backgroundImage: `url(${bgUrl})`, }}
      >
        <div className="overlay" style={{backgroundColor: "#000000", opacity: "0.6"}}></div>
        <h2 className="cs_banner_title cs_white_color cs_fs_72">{title}</h2>
        <p className="cs_banner_subtitle cs_contact_color cs_fs_20 cs_medium m-0">
          {subTitle}
        </p>
        <div className='center-button'>
          <a href={contUrl} className="cs_button cs_white_color cs_button_primary home-btn-primary">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}