import React from 'react';
import Spacing from '../../Spacing';

export default function AboutSection({
  imgUrl,
  spiningImgUrl,
  title,
  subTitle,
  featureList,
}) {
  return (
    <section className="cs_about cs_style_1">
      <div className="container">
        <div className="row align-items-center flex-lg-row flex-column-reverse cs_gap_y_40">
          <div className="col-lg-7">
            <Spacing md="55" />
            <div className="cs_about_img">
              <img src={imgUrl} alt="About" className='rounded-4 mb-5'/>
              <div className="cs_about_mini_img">
                <img
                  src={spiningImgUrl}
                  alt="Mini"
                  className="cs_rotate_animation"
                />
                <img
                  src="/images/icons/tick.svg"
                  alt="Right"
                  className="cs_right_icon"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="cs_about_content">
              <div className="cs_section_heading cs_style_1">
                <h2 className="cs_section_title main_color cs_fs_72 m-0">{title}</h2>
                <div className="cs_height_5" />
                <h3 className="cs_section_subtitle text-uppercase cs_semibold m-0 cs_fs_32" style={{color:'#282828'}}>
                  {subTitle}
                </h3>
              </div>
              <Spacing md="72" lg="72" />
              {featureList.map((item, index) => (
                <>
                <p key={index}>{item.featureListTitle}</p>  
          <div className='center-button'>
          <a href={'/contact'} className="cs_button cs_white_color cs_button_primary home-btn-primary">
            Contact Us
          </a>
        </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
