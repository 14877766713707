import React from "react";
import { Link } from 'react-router-dom';

export default function Feature({ iconSrc, title, subTitle }) {
  return (
    <div className="cs_feature cs_style_1 cs_shadow_1 cs_radius_25 cs_white_bg customHoverClass">
      <Link to="/services" >
      <h2 className="cs_feature_title  cs_semibold cs_fs_40 cs_center">
        <span className="cs_feature_icon footer_color customHoverClass cs_center rounded-circle">
          <img src={iconSrc} alt="Icon" />
        </span>
        <span>{title}</span>
      </h2>
      </Link>
      <p className="m-0 text-center ">{subTitle}</p>
    </div>
  );
}
// path: '/services',