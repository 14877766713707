import React from 'react';
import AboutSection from '../Section/AboutSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import { pageTitle } from '../../helpers/PageTitle';
import HeroStyle2 from '../Hero/HeroStyle2';
// import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
// import DepartmentSection from '../Section/DepartmentSection';
import AwardSection from '../Section/AwardSection';


const featureListData = [
  
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Care Consultancy',
    subTitle:
"We provide expert guidance on optimising operations, maintaining regulatory compliance, and managing finances to ensure your care practice’s success."      
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Website Design and Development',
    subTitle:
        "Our experienced team will create a website that reflects your brand and engages your target audience, offering a user-friendly experience."   
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Integration of Management Tools',
    subTitle:
"We seamlessly integrate essential management software into your website, facilitating scheduling, appointment booking, and communication. "    
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Staffing Management Portal',
    subTitle:
"A centralised hub for managing employee profiles, schedules, leave requests, and training records. "     
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Timesheet Management System',
    subTitle: 
"Automate timesheet submission to save time and enhance accuracy. "     
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Transition to Paperless Operations',
    subTitle: 
"Implement digital document management for secure and efficient record-keeping. "     
  },
  // {
  //   iconSrc: '/images/home_1/teamwork.svg',
  //   title: "Sponsorship Management",
  //   subTitle:"Efficiently track sponsors, agreements, and donations with our integrated system. "
  // }
];
const featureData = [
  {
    title: 'Enhanced Credibility and Brand Image',
    subTitle:'A professional website boosts credibility and trust with potential clients. ',
    iconUrl: '/images/icons/professional.svg',
  },
  {
    title: 'Improved Patient Engagement',
    subTitle:
      'User-friendly websites facilitate better communication and patient experience.  ' ,
         iconUrl: '/images/home_1/integrity.svg',
  },
  {
    title: 'Increased Efficiency and Growth Potential',
    subTitle:
      'Streamlined operations and regulatory compliance lead to business growth. ',
    iconUrl:'/images/home_1/teamwork.svg',
  },
  {
    title: 'Productivity and Profitability',
    subTitle:
      'Digital tools and referral program optimisation drive better results. ',
    iconUrl:'/images/home_1/teamwork.svg',
  },
 
];


export default function Home() {
  pageTitle('Home');
  return (
    <>
      <HeroStyle2
        title="Welcome to Cosesha"
        subTitle=" Your Trusted Partner in Care Consultancy! "
        bgUrl="/images/bannernew.png"
        imgUrl="/images/home_1/the_home.png"
       
        // btnText="Contact Us"
        // btnUrl="/contact"
        funfactList={[
          { number: '10+', title: 'Years of experience' },
          { number: '95%', title: 'Patient satisfaction rating' },
          { number: '30+', title: 'Healthcare Projects Delivered' },
          { number: '10+', title: 'Healthcare consultancy providers on staff' },
        ]}
      />


      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Brief Overview Of Services" data={featureListData}  />
      </Section>
      <Section>
        <AboutSection
          imgUrl="/images/home_1/abouthome.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="Cosesha"
          featureList={[
            {
              featureListTitle:
"Cosesha offers comprehensive consultancy services to empower healthcare providers with the tools and technology needed to thrive. We understand the unique challenges of the care industry and are dedicated to streamlining operations, improving efficiency, enhancing patient care, ensuring regulatory compliance, and keeping you informed on the latest industry trends. "            },
          ]}
        />
      </Section>

      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Why Choose Us?" data={featureData} />
      </Section>

      {/* <Section topMd={170} topLg={145} topXl={90}>
        <FeaturesSectionStyle3
          sectionTitle="Why Choose "
          sectionTitleUp="COSESHA"
          
          data={featureData}
        />
      </Section> */}
      
      <Section>
        <Banner
          bgUrl="images/home_1/contact_us_now.jpg"
          // imgUrl="images/home_1/start_consulting.jpg"
          title=" Get Started"
          subTitle="Contact us today for a consultation. We are here to help you!"
          contUrl={'/contact'}
        />
      </Section>
    </>
  );
}
