

import cosesha_logo  from '../../../src/wey.png';

import React from 'react';
import MenuWidget from '../Widget/MenuWidget';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import TextWidget from '../Widget/TextWidget';
import SocialWidget from '../Widget/SocialWidget';
import NewsletterStyle2 from '../Widget/NewsletterStyle2';
const menuDataOne = [
  { title: "Home", href: "/" },
  { title: "About Us", href: "/about" },
  { title: "Services", href: "/services" },
  { title: "FAQs", href: "/faqs" },
  { title: "Contact", href: "/contact" },
];
  const menuDataTwo = [
    {title: "Your Trusted Partner for Care Consultancy"},
    {title: "Your one-stop shop for all your care consultancy needs."},
 
    
  ];

// const menuDataTwo = [
//   { title: "Cosecha is a care consultancy company with over 30 years" },
//     // title2:"of experience in the NHS and social care. We offer expert support in setting up care companies, CQC registration, receiving referrals, managing embargoes, and staff training. Our commitment is to providing quality care and support. [Contact us today](contact.html) for a consultation.'
   
// ];

const menuDataThree = [
  { title: "Privacy Policy", href: "javascript:void(0);" },
  { title: "Terms of Use", href: "javascript:void(0);" },
  { title: "Site Map", href: "javascript:void(0);" },
];

export default function FooterStyle2() {
  return (
    <footer className="cs_footer cs_style_2 cs_adjust   cs_white_color mt-5" >
     
      <NewsletterStyle2
        title="For latest news"
        subTitle="To get the latest news about healthcare from our experts"
      />
      <div className="container" >
        <div className="cs_footer_in ">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl={cosesha_logo}
                text="Cosesha Medical Consultancy"

              />
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
            <ContactInfoWidget />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      
      <div className="cs_footer_bottom " >
        <div className="container">
          <div className="cs_copyright">
            Copyright © 2024 Cosesha Healthcare Consultancy. All rights reserved. 
          </div>
        </div>
      </div>
    </footer>
  );
}