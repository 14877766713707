import { Icon } from '@iconify/react';
import React from 'react';

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget ">
      <li>
        <i className="orange_bg">
          <Icon icon="ep:location" />
        </i>
        Trafford Park, Centenary Way, Manchester, M50 1RF
      </li>
      <li>
        <i className="orange_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        +44 7496645565
      </li>
      <li>
        <i className="orange_bg">
          <Icon icon="bi:envelope" />
        </i>
       info@cosesha.co.uk
      </li>
    </ul>
  );
}
